<template>
	<v-container fluid class="pa-0">
		<v-dialog
				ref="dialog"
				v-model="modal"
				:return-value.sync="time"
				persistent
				width="290px"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
						outlined
						v-model="time"
						:label="label"
						:dense="dense"
						:rules="rules"
						prepend-icon="mdi-clock-time-four-outline"
						readonly
						v-bind="attrs"
						v-on="on"
				></v-text-field>
			</template>
			<v-time-picker
					v-if="modal"
					v-model="time"
					full-width
					format="24hr"
			>
				<v-spacer></v-spacer>
				<v-btn
						text
						color="primary"
						@click="modal = false"
				>
					بازگشت
				</v-btn>
				<v-btn
						color="primary"
						@click="onOkClick"
				>
					ثبت
				</v-btn>
			</v-time-picker>
		</v-dialog>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			modal: false,
			// time: '00:00',
		};
	},
	props: {
		value: {
			// v-model instance
			// type: String,
			required: true,
		},
		label: {
			required: true,
			type: String,
		},
		locale: {
			required: false,
			type: String,
			default: "fa-IR",
		},
		rules: {
			type: Array,
			default: () => [],
		},
		dense: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		onOkClick() {
			this.$refs.dialog.save(this.time);
		},
	},
	computed: {
		time: {
			get() {
				return this.value;
			},
			set(time) {
				this.$emit("input", time);
			},
		},
	}
};
</script>

<style></style>
